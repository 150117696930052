// Snackbars & Accordions
.MuiPaper-root {
  background-color: $card-background-color !important;
  max-width: 500px;
  padding: 16px;
  box-shadow: none !important;

  &.MuiAccordion-root {
    max-width: none !important;
    color: $white;
    border-radius: 8px !important;

    .MuiAccordionSummary-root,
    .MuiAccordionDetails-root {
      padding: 0 !important;
    }
  }
}

.snackbar-color-success {
  .MuiPaper-root {
    background-color: $success !important;
  }
}

.snackbar-color-error {
  .MuiPaper-root {
    background-color: $error !important;
  }
}

// Links
a {
  color: $info;
}

// Buttons
button {
  position: relative;
  display: inline-flex !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  justify-content: center;
  margin: 8px !important;
  // min-width: 130px !important;
  width: fit-content;
  min-height: 40px !important;
  max-height: 40px;
  border-radius: 4px;
  background-color: $black-1 !important;
  color: $white !important;
  padding: 0px 16px;
  border: none;
  font-size: 0.8rem !important;
  text-transform: none !important;
  font-family: $default-font !important;
  font-weight: bold !important;
  img {
    max-height: 20px;
  }

  cursor: pointer;
  &.btn-white {
    background-color: $white !important;
    color: $background-color !important;
  }
  &.no-bg {
    background-color: transparent !important;
  }
  .material-icons {
    top: 8px;
    &.icon-prefix {
      left: 16px !important;
    }
  }
  .btn-text {
    position: relative;
    margin-right: 4px !important;
  }
  &.icon-btn {
    padding: 0px !important;
    min-width: 40px !important;

    .material-icons {
      position: absolute;
      top: 8px;
      left: 8px;
    }
    img {
      max-height: 20px;
    }
  }
  &.btn-no-margins {
    margin: 0px;
  }
  &.min-w0 {
    min-width: 0px !important;
    padding: 0px !important;
  }
  &.border-btn {
    border: solid 1px $white;
  }
}

button:disabled {
  opacity: 0.3;
}

// Inputs
label {
  text-align: left;
  p {
    margin-bottom: 8px;
  }
}
input {
  border: 1px solid $grey-1;
  background-color: #0e0e0e;
  border-radius: 6px;
  padding: 12px;
  color: white;
  font-size: 1.2rem;
  width: calc(100% - 26px);
}

input:disabled {
  color: $text-faded;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[type="checkbox"] {
  width: 20px !important;
  height: 20px !important;
}

textarea {
  min-height: 80px !important;
  width: 95%;
  background: transparent;
  border-radius: 4px;
  border: solid 1px $grey;
  color: $white;
  font-family: inherit;
  padding: 8px;
}

.checkbox-icon {
  height: 20px;
  width: 20px;
  border: 1px solid $grey-1;
  border-radius: 4px;
  .checked {
    border-radius: 4px;
    background-image: $blue-gradient;
    width: 12px;
    height: 12px;
    margin: auto;
    margin-top: 4px;
  }
}
.MuiFormControlLabel-root {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.MuiSlider-thumb {
  background-image: url("../images/icons/SliderThumb.svg");
}
.MuiSlider-rail {
  color: $black-1;
  height: 8px !important;
  border: 1px solid $grey-1;
}
.MuiSlider-track {
  background-image: $blue-gradient !important;
  height: 8px !important;
  border: 1px solid $grey-1;
}

.MuiSelect-select {
  width: 350px !important;
  color: $white !important;
  text-align: start;
}

.MuiInputLabel-root {
  color: $white !important;
  background-color: $background-color;
  padding-right: 8px !important;
  padding-left: 4px !important;
}

.MuiInputBase-root {
  border: 1px white solid;
}

.MuiSelect-outlined {
  border: $white 1px solid;
}

.MuiSelect-icon {
  color: $white !important;
}

.MuiMenuItem-root {
  color: $white !important;
  text-align: start;
  border-bottom: 1px $grey-1 solid !important;
  min-height: 50px !important;
}

.MuiMenu-paper {
  height: 400px;
}
// Tabs
.MuiBox-root {
  margin-bottom: 16px;
  button {
    font-weight: normal !important;
    background-color: transparent !important;

    &.Mui-selected {
      color: $white !important;
    }
    color: $text-faded !important;
  }
  .MuiTabs-indicator {
    background-color: $white !important;
  }
}

// Icons
.material-icons {
  &.card-icon {
    height: 62px;
    width: 62px;
    background-color: $background-color;
    border-radius: 8px;
    font-size: 2rem;
    align-items: center;
    justify-content: center;
    display: flex;
    img {
      height: 32px;
    }
  }
}

.card-icon {
  height: 60px;
}

// Tables
.MuiTableSortLabel-root {
  color: $white !important;
}
.MuiTableSortLabel-icon {
  color: $white !important;
}

thead {
  th {
    font-family: $default-font !important;
    color: $text-white !important;
    border-bottom: none !important;
    padding-bottom: 4px !important;
    padding-left: 0 !important;
  }
}
tbody {
  tr {
    opacity: 0;
    animation: fadeIn 0.1s calc(var(--animation-number) * 20ms) ease-in-out
        forwards,
      translateY 0.1s calc(var(--animation-number) * 20ms) ease-in-out forwards;
  }

  th,
  td {
    font-family: $default-font !important;
    color: $text-faded !important;
    border-top: 1px solid $grey-1 !important;
    border-bottom: none !important;
    padding: 0 !important;
    padding-right: 8px !important;
  }
}

.table-item-with-image {
  display: flex;
  gap: 8px;
  img {
    border-radius: 4px;
    max-height: 32px;
    min-height: 32px;
    max-width: 32px;
    min-width: 32px;
    object-fit: cover;
    margin-top: auto;
    margin-bottom: auto;
  }
  span {
    padding-top: 6px;
    // display: block;
    // width: calc(100% - 20px);
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }
}

.table-end-call-with-button {
  display: flex;
  justify-content: space-between;
  span {
    margin: auto;
    margin-left: 0;
  }
}

// Status Badges
.status-badge {
  height: 28px;
  display: flex;
  border-radius: 16px;
  width: fit-content;
  padding: 4px 8px 0px 8px;

  &.verified {
    background-color: $verified;
    color: $info;
  }
  &.pending {
    background-color: $pending;
    color: $warn;
  }
  &.rejected {
    background-color: $rejected;
    color: $error;
  }
}

.status-badge,
.transaction-type {
  .material-icons {
    font-size: 1.2rem;
    padding: 3px;
  }
  img {
    height: 1.2rem;
    padding: 3px;
  }
}

// Charts
.line-chart {
  margin: auto;
}

tspan,
.recharts-legend-item-text {
  font-size: 0.8rem;
}

.recharts-surface {
  border-radius: 4px;
}

.recharts-wrapper {
  margin: auto;
}

g:focus,
path:focus {
  outline: none !important;
}

.pie-chart-wrapper {
  transform: rotate(270deg);
  .recharts-tooltip-wrapper {
    transform: rotate(-270deg) !important;
  }
}

.pie-chart-center-text {
  text-align: center;
  position: absolute;
  left: calc(50% - 30px);
  top: 120px;
}

.pie-tooltip {
  transform: translate(215px, -230px);
}

.graph-tooltip {
  background-color: $grey-1;
  border-radius: 8px;
  padding: 16px;
}

// Toggle Groups

.MuiToggleButtonGroup-root {
  .MuiToggleButton-root {
    min-width: 0 !important;
    &.Mui-selected {
      color: $white !important;
    }
    color: $text-faded !important;
    min-width: 60px;
  }
}
