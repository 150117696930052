// Margin Bottom
$sizes: "0" 0, "1" 1px, "2" 2px, "4" 4px, "4rem" 4rem, "6" 6px, "-6" -6px,
  "8" 8px, "-8" -8px, "16" 16px, "32" 32px, "48" 48px, "62" 62px, "100" 100px,
  "120" 120px, "-auto" auto;

@each $name, $size in $sizes {
  .m#{$name} {
    margin: $size;
  }

  .mt#{$name} {
    margin-top: $size !important;
  }

  .mb#{$name} {
    margin-bottom: $size !important;
  }

  .ml#{$name} {
    margin-left: $size !important;
  }

  .mr#{$name} {
    margin-right: $size !important;
  }

  .pt#{$name} {
    padding-top: $size !important;
  }

  .pb#{$name} {
    padding-bottom: $size !important;
  }

  .pl#{$name} {
    padding-left: $size !important;
  }

  .pr#{$name} {
    padding-right: $size !important;
  }

  .p#{$name} {
    padding: $size !important;
  }
}

// Width
.w100p {
  width: 100% !important;
}

.w50p {
  width: 50% !important;
}

.min-w70 {
  min-width: 70px;
}
.min-w180 {
  min-width: 180px;
}

.min-w300 {
  min-width: 300px !important;
}

.max-w600 {
  max-width: 600px;
}

.max-w500 {
  max-width: 500px;
}

.max-w400 {
  max-width: 400px;
}

.max-w200 {
  max-width: 200px;
}

.max-w90p {
  max-width: 90%;
}

// Height
.min-h150 {
  min-height: 150px;
}

.min-h48 {
  min-height: 48px;
}

// Positions
.pos-abs {
  position: absolute;
}

.pos-rel {
  position: relative;
}

// Displays
.d-flex {
  display: flex;
}

.flex-grow {
  flex-grow: 1;
}

.w-100 {
  width: 100%;
}

// Other
.cursor-pointer {
  cursor: pointer;
}

.jc-center {
  justify-content: center;
}

.jc-space-around {
  justify-content: space-around;
}

.jc-space-between {
  justify-content: space-between;
}

.flex-d-col {
  flex-direction: column;
}
