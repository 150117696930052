.onboarding-page {
  text-align: center;
}

.user-info-form {
  @include card-background();
  height: fit-content;
  width: 500px;

  &.onboarding {
    margin: auto;
    margin-top: 48px;
  }
}

.user-form-names {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 16px;
  label {
    flex-grow: 1;
  }
}

.onboarding-step-component {
  min-height: 80vh;
  padding: 3rem;
  position: relative;
}

.onboarding-nav-container {
  position: fixed;
  width: 100%;
  bottom: 3rem;
  margin-left: -6rem;
}

.onboarding-nav-buttons {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.view-dashboard-button {
  position: absolute !important;
  left: calc(50% - 90px);
  top: calc(50% - 40px);
  min-width: 180px;
}

.do-later-button {
  margin-left: 6rem;
  position: fixed;
  bottom: 3rem;
  left: -3rem;
}

.continue-button {
  margin-left: 6rem;
  position: fixed;
  bottom: 3rem;
  right: 3rem;
}

.spotify-profile-picture {
  height: 150px;
  border-radius: 50%;
}

.connect-spotify-tooltip {
  padding: 16px !important;
  img {
    max-width: 400px;
  }
}

.choose-track-to-add-card {
  @include card-background();
  max-width: 1000px;
  margin: auto;
  margin-top: 32px;
}

.verified-account-image {
  height: 100px;
  width: 100px;
}
