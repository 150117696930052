.nav-container {
  height: 90vh;
  position: relative;
  width: 200px;
  transition: all 0.5s ease-in-out;
  background-color: $card-background-color;
  border-radius: 6px;
  margin-right: 20px;
  .logo-img {
    width: 100px;
    min-height: 50px;
    object-fit: contain;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 68px;
    padding-left: 16px;
    background-color: black;
    margin: 8px;
    border-radius: 6px;
    transition: all 0.5s ease-in-out;
    transition-property: padding-right, width;
  }
  &.minimized {
    width: 100px !important;
    .logo-img {
      width: 80px;
      object-fit: contain;
      max-height: 50px;
      padding-top: 12px !important;
      padding-bottom: 12px !important;
      padding-right: 0px !important;
      padding-left: 0px !important;
    }
  }
}

.nav-sidebar {
  .nav-link-item {
    display: flex;
    justify-content: start;
    border-radius: 6px;
    color: $grey !important;
    padding: 4px;
    margin: 8px;
    min-height: 40px;
    text-decoration: none;
    transition: margin 0.5s ease-in-out;

    &.active {
      background-color: $background-color;
      color: white !important;
    }
    &.minimized {
      justify-content: center;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
    }

    .nav-link-icon {
      padding: 8px;
    }
    .nav-link-text {
      display: block;
      opacity: 1;
      padding: 8px;
      font-size: 1rem;
      text-decoration: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: all 0.5s ease-in-out;
      &.minimize {
        display: none;
        opacity: 0;
      }
    }
  }
}

.nav-footer {
  position: absolute;
  border-top: solid 1px $grey-1;
  bottom: 0;
  width: 180px;
  margin: 10px;
  padding-top: 8px;
  transition: width 0.5s ease-in-out;
  &.minimize {
    width: 75px !important;
  }
}

.minimize-arrow {
  animation: rotate180 0.5s ease-in-out forwards;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $grey-1;
  position: absolute;
  right: -10px;
  top: 35px;
  box-shadow: 0px 0px 10px $background-color;
  .material-icons {
    position: relative;
    top: 2px;
    left: 2px;
    font-size: 1rem;
  }
}
