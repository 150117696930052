@mixin slow-fade {
  animation: fadeIn 2s ease-in-out;
}

@mixin quick-fade {
  animation: fadeIn 0.5s ease-in-out;
}

@mixin card-background {
  @include quick-fade();
  background-color: $card-background-color;
  border-radius: 8px;
  padding: 16px;
}

@mixin card-background-blue {
  @include card-background();
  background-image: $blue-gradient-faded !important;
}

@mixin text-blue-gradient {
  color: $blue-gradient;
  background: $blue-gradient;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
