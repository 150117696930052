.feedback-modal-container {
  padding: 3rem;
  min-height: 100%;
  height: fit-content;
  background-color: $background-color;
  text-align: center;
  overflow: scroll;
}

.MuiModal-root {
  overflow: scroll;
}

.playback-pic {
  gap: 16px;
  img {
    max-height: 48px;
    border-radius: 4px;
  }
}
