.progress-bar-container {
  display: flex;
  position: relative;
  width: 100%;
  gap: 16px;
  min-height: 20.5px;
}

.progress-bar {
  min-width: 50px;
  flex-grow: 1;
  position: relative;
  padding-top: 6px;

  .inner-bar {
    height: 10px;
    border-radius: 10px;
    background-image: $blue-gradient;
    &.yellow {
      background-image: $yellow-gradient;
    }

    &.pink {
      background-image: $pink-gradient;
    }
  }
  .background {
    width: 100%;
    opacity: 0.3;
  }

  .foreground {
    width: var(--width);
    animation: progress 0.5s forwards ease-in-out;
  }
  .bubble {
    animation: progressBubble 0.5s forwards ease-in-out;
    min-width: 0px;
    left: calc(var(--width) - 10px);
    top: 1px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-image: $blue-gradient;

    &.yellow {
      background-image: $yellow-gradient;
    }
    .inner-bubble {
      width: 12px;
      height: 12px;
      background-color: $white;
      margin: auto;
      top: 4px;
      border-radius: 50%;
    }
  }
}

// Radial Progress Bar
section {
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 0.5rem;
}

.radial-progress {
  height: 200px;
  width: 200px;
  transform: rotate(270deg);
}

#progress {
  fill: transparent;
  stroke-width: 14px;
  stroke-linecap: round;
  &.lg {
    animation: progressRadialLg 1s forwards ease-in-out;
  }
  &.md {
    animation: progressRadialMd 1s forwards ease-in-out;
  }
  &.sm {
    animation: progressRadialSm 1s forwards ease-in-out;
  }
}

#track {
  fill: transparent;
  stroke-width: 14px;
  opacity: 0.2;
}
