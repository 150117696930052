.loading-container {
  animation: rotate360 1s ease-in-out infinite;
  margin: 16px;
  position: relative;
  height: 150px;
  width: 150px;
  &.loading-small {
    height: 40px !important;
    width: 40px !important;
  }
  .circle {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: $white;
    position: absolute;
    left: calc(50% - 25px);
    &.circle-small {
      height: 15px !important;
      width: 15px !important;
      left: calc(50% - 7.5px) !important;
    }
  }
  .circle-1 {
    top: 0;
  }
  .circle-2 {
    bottom: 0;
  }
}
