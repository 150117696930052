// Background Colors
$background-color: #0e0e0e;
$card-background-color: rgba(28, 28, 30, 1);

// Basic Colors
$black: black;
$black-1: #1c1c1e;
$white: white;
$grey: #99989f;
$grey-1: #383838;

// Text Colors
$text-faded: #99989f;
$text-faded-1: rgb(145, 145, 145);
$text-white: rgb(255, 255, 255);
$text-blue: #05befe;

// Gradients
$blue-gradient: linear-gradient(255.56deg, #06befe 5.59%, #406bff 96.78%);
$blue-gradient-faded: linear-gradient(
  255.56deg,
  rgba(6, 190, 254, 0.1) 5.59%,
  rgba(64, 107, 255, 0.1) 96.78%
);
$blue-gradient-faded-player: linear-gradient(
  230deg,
  rgba(6, 190, 254, 0.1) 5.59%,
  #06c0fe79 20.59%,
  #406dff50 76.78%,
  rgba(64, 107, 255, 0.1) 96.78%
);
$yellow-gradient: linear-gradient(90deg, #ffae18 0%, #fdd07a 100%);
$pink-gradient: linear-gradient(90deg, #f8005e 0%, #f8629b 100%);

// Other Brand Colors
$spotify-green: #1ed760;

// Notifications
$success: #009b51;
$warn: #ffae11;
$error: #f31155;
$info: #05befe;

// Statuses
$verified: #05befe29;
$pending: #ffae1133;
$rejected: #f311551a;

$pink: #f8005e;
$pink-light: #f8629b;
$pink-faded: #f8005f29;
$pink-light-faded: #f8629c25;
