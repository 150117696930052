.logout-button {
  padding: 8px;
  display: flex;
  justify-content: start;

  cursor: pointer;
  .logout-icon {
    padding: 8px;
  }
  .logout-text {
    padding: 8px;
    font-size: 1rem;
    color: white !important;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.minimize {
    justify-content: center;
  }
}
