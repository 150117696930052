.footer {
  width: calc(100% - 6rem);
  position: absolute;
  background-color: $card-background-color;
  height: 50px;
  padding: 16px;
  padding-left: 3rem;
  padding-right: 3rem;

  img {
    height: 25px;
  }
}
