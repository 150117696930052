@import url("https://fonts.cdnfonts.com/css/circular-book");

$default-font: "Circular Book", sans-serif;

body {
  font-family: $default-font;
  color: white;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  color: white;
}

h1 {
  font-size: 4.5rem;
}

h2 {
  font-size: 2.5rem;
}

h3 {
  font-size: 1.5rem;
}

p {
  font-size: 1rem;
  &.small {
    padding-top: 4px;
    font-size: 0.8rem;
  }
}

.text-faded {
  color: $text-faded;
}

.text-white {
  color: $text-white !important;
}

.text-blue {
  color: $text-blue;
}

.text-blue-gradient {
  @include text-blue-gradient;
}

.text-left {
  text-align: start !important;
}
.text-right {
  text-align: end !important;
}

.text-center {
  text-align: center;
}

.fw-normal {
  font-weight: normal;
}

.fs-16 {
  font-size: 16px !important;
}
