.soundwave-container {
  height: 30px;
  width: auto;
  display: flex;
  align-items: flex-end;
  margin-right: 15px;
}
.bar {
  width: 2px;
  height: 100%;
  background-image: $blue-gradient;
  border-radius: 2px;
  margin: 0 2px;
  animation: equalizer 1.9s steps(20, end) infinite;
}
.bar:nth-child(1) {
  animation-duration: 1.9s;
}
.bar:nth-child(2) {
  animation-duration: 2s;
}
.bar:nth-child(3) {
  animation-duration: 1.7s;
}
.bar:nth-child(4) {
  animation-duration: 2.1s;
}
