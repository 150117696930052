.App {
  display: flex;
  // background-color: $background-color;
  background-size: 100%;
  min-height: calc(100% + 2px);
  padding: 3rem;
}

.page-content {
  width: 100%;
  margin-bottom: 3rem;
}

.holding-text {
  @include slow-fade;
  position: absolute;
}

.landing-page {
  min-height: 100vh;
  background-color: $background-color;
  text-align: center;
  justify-content: center;
}

.small-screen-placeholder {
  padding: 3rem;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 50%;
  background-image: url("https://uploads-ssl.webflow.com/63e7f7e770738021a2190b76/63e8b0882d6553290e7441aa_Waves.svg");
  img {
    height: 20px;
  }
  .inner-items {
    margin-top: calc(40vh - 100px);
    justify-content: center;
    img {
      height: 108px;
    }
  }
}

.onboarding-page {
  position: relative;
  background-color: $background-color;
  background-size: 100%;
  min-height: 91vh;
  padding: 3rem;
  text-align: center;
}

.centered-loading {
  justify-content: center;
  display: flex;
}

.main-logo-banner {
  width: 300px;
  margin: 16px;
}

.card-title {
  display: flex;
  gap: 16px;
  .titles {
    padding-top: 6px;
    .text-number {
      font-size: 1.5rem;
      font-weight: bold;
    }
  }
}

.continue-btn {
  position: absolute !important;
  bottom: 3rem;
  right: 3rem;
}

body {
  background-color: $background-color;
}
