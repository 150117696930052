.playlist-item {
  display: flex;
  gap: 16px;
  text-align: start;
  border-radius: 8px;
  overflow: hidden;
  &.is-connected {
    opacity: 0.5;
  }
  img {
    height: 80px;
    width: 80px;
    border-radius: 16px;
    object-fit: cover;
  }
  &:hover {
    background-color: $card-background-color;
  }
  .playlist-item-checkbox-container {
    padding-top: 20px;
  }
  .playlist-titles {
    padding-top: 16px;
    .title {
      font-size: 1.2rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      text-wrap: wrap;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .followers {
      font-size: 0.8rem;
    }
  }
  &:has(input[type="checkbox"]:checked) {
    .playlist-titles {
      .title {
        @include text-blue-gradient();
      }
    }
  }
}

.playlists-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 16px;
  margin: auto;
}

.playlist-card {
  @include card-background;
  height: fit-content;
}

.playlist-image {
  height: 150px;
  width: 150px;
  object-fit: cover;
  border-radius: 8px;
}

.playlist-select-footer {
  @include card-background;
  background-color: $grey-1;
  height: 50px;
  width: calc(100% - 348px);
  position: fixed;
  bottom: 0;
}

.item-playing {
  opacity: 1;
  animation: fadeIn 0.5s ease-in-out, moveGradient 10s ease-in-out infinite;
  background: $blue-gradient-faded-player;
}

.accordion-expand-icon {
  color: $white !important;
}
