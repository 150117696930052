.dashboard-banner {
  display: flex;
  gap: 32px;
  .profile-pic {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    object-fit: cover;
  }
  .verified-badge {
    top: 10px;
    right: 0px;
  }
}

.dashboard-banner-text {
  padding-top: 32px;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  min-height: 150px;
}

.top-cards {
  margin-top: 16px;
  height: auto;
  display: flex;
  gap: 16px;
  width: 100%;
  .two-cards {
    min-width: calc(50% - 48px);
  }
}

.bottom-cards {
  margin-top: 16px;
  min-height: 400px;
  display: flex;
  gap: 16px;
  width: 100%;
  .playlists-connected-card {
    max-width: 350px;
    position: relative;
  }
}
.title-card {
  @include card-background;
  flex-basis: 0;
  flex-grow: 1;
}

.overlay-blur {
  position: absolute;
  top: 0;
  left: -16px;
  width: calc(100% + 32px);
  height: 100%;
  text-align: center;
  backdrop-filter: blur(10px);
}
